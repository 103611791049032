import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReferalsPage from './ReferalsPage';

const container = document.getElementById('root');
const root = createRoot(container); 
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router basename={'/'}>
        <Routes>
          <Route exact path="/" element={
              <App />
          } />
          <Route exact path="refs" element={
              <ReferalsPage />
          } />
        </Routes>
      </Router>
    </Provider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
