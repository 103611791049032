import './index.css';
import React, { useRef, useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Stack, StackItem, Axis, ContentContainer } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, A, Caption } from './TypographyComponents';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import uuid from 'react-uuid';
import _ from 'lodash';
import { Color } from './flatColors';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Pagination from '@mui/material/Pagination';
import { 
  getReportData,
  resetState,
} from "./redux/actions";


function ReportsPage() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;

  return (
    <ThemeProvider theme={stateObj.theme} >
      <ContentContainer>
        <Body></Body>
      </ContentContainer>
    </ThemeProvider>
  );
}

function Body() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const theme = stateObj.theme;
  const navigate = useNavigate();
  const lt = stateObj.lt;
  const ref = useRef();
  const [divRef, setDivRef] = useState(null);
  let doit;

  useEffect(()=>{
    setDivRef(ref.current);
  },[]);

  const callback = ()=> {
    if (divRef === null) {
      return;
    }
    clearTimeout(doit);
    doit = setTimeout(function() {
      let height = divRef.clientHeight;  
      window.top.postMessage({resize: height}, '*');
    }, 100);
  }

  return(
    <div width="100%" ref={ref}>
      <Column width="100%" center color='rgb(28,28,28)'>
        <Column width='calc(100%)'>
          <DataTable callback={callback} status={0} title='В РАБОТЕ' showInvoice={true}></DataTable>
          <DataTable callback={callback} status={1} title='ЗАВЕРШЕННЫЕ' showInvoice={false}></DataTable>
          <DataTable callback={callback} status={2} title='ОТМЕНЕННЫЕ' showInvoice={false}></DataTable>
        </Column>
      </Column>
    </div>
  );
}


function DataTable(props) {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const theme = stateObj.theme;
  //const [value, setValue] = React.useState(0);
  const inPocessData = stateObj.inPocessData;
  const completedData = stateObj.completedData;
  const cancelledData = stateObj.cancelledData;
  const query = new URLSearchParams(window.location.search);
  const user = query.get('user');
  const inProcessDataProcessing = stateObj.inProcessDataProcessing;
  const completedDataProcessing = stateObj.completedDataProcessing;
  const cancelledDataProcessing = stateObj.cancelledDataProcessing;
  const lt = stateObj.lt;
  const [page, setPage] = React.useState(1);
  let processing;

  useEffect(()=>{
    //let user = 'info@vessna.io';
    //dispatch(resetState({processing:1}));
    //console.log(user);
    dispatch(getReportData(user, props.status, page)); //0 - in process, 1 - completed, 2 - cancelled, 99 - all
    props.callback();
  },[page, processing]);

  props.callback();

  let reportData;
  
  if (props.status === 0) {
    reportData = inPocessData;
    processing = inProcessDataProcessing;
  } else if (props.status === 1) {
    reportData = completedData;
    processing = completedDataProcessing;
  } else if (props.status === 2) {
    reportData = cancelledData;
    processing = cancelledDataProcessing;
  }

  const createData = (nn, dt, stage, good, price, qty, total, invoice) => {
    return { nn, dt, stage, good, price, qty, total, invoice };
  }

  //console.log(reportData);

  let rows = [];
  if (reportData && _.isEmpty(reportData.deals) === false) {
    let temp = _.flattenDeep(reportData.products);
    let newProd = [];
    temp.map((v,i)=>{
      let k = _.findIndex(newProd, (o)=> o.ID === v.ID);
      if (k < 0) {
        newProd.push(v);
      }
    });
    reportData.deals.map((deal)=>{
      let date = new Date(deal.DATE_CREATE.substring(0, deal.DATE_CREATE.indexOf("T")));
      let formattedDate = `${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`;
      let n = _.findIndex(reportData.stages, (o) => o.hasOwnProperty(deal.STAGE_ID) === true);
      let st;
      if (n !== -1) {
        st = reportData.stages[n][deal.STAGE_ID];
      }
      let prod = _.filter(newProd, (o)=>o.OWNER_ID === deal.ID);
      
      if (prod.length === 0) {
        prod.push({PRODUCT_NAME:'', PRICE:0, QUANTITY:0});
      }
      //console.log(prod.length);
      prod.map((v)=>{
        return(rows.push(createData(deal.UF_CRM_61B1C605DE7EC ? deal.UF_CRM_61B1C605DE7EC : deal.ID, formattedDate, st, v.PRODUCT_NAME, (v.PRICE).toLocaleString(undefined, { minimumFractionDigits: 2 }), v.QUANTITY, (v.PRICE * v.QUANTITY).toLocaleString(undefined, { minimumFractionDigits: 2 }), deal.UF_CRM_1664458744)));
      })
    });
  }
  
  const handlePageChange = (e,p) => { 
    if (page !== p) {
      setPage(p); 
      if (props.status === 0) {
        dispatch(resetState({inProcessDataProcessing:1}));
      } else if (props.status === 1) {
        dispatch(resetState({completedDataProcessing:1}));
      } else if (props.status === 2) {
        dispatch(resetState({cancelledDataProcessing:1}));
      } 
    }
  }


  return (
    <>
    <Column>
      { _.isEmpty(rows) === false &&
        <Column top={20} bottom={15}>
          { reportData.pageInfo.total_pages > 1 ?
          <>
          <Row bottom={10}><Header4 sx={{color:Color.flatWhite}}>{props.title}</Header4></Row>
          <Row self={Axis.self.center} top={10} bottom={20}><MyPagination count={reportData.pageInfo.total_pages} color='secondary' onChange={handlePageChange} sx={{color:'white'}}></MyPagination></Row>
          
          <div style={{display: processing === 1 ? 'block': 'none'}}>
            <Column>
              <Row center width='100%'>
                <Skeleton sx={{width:'100%', bgcolor: Color.flatWhiteDark}}/>
              </Row>
              <Row center width='100%'>
                <Skeleton sx={{width:'100%', bgcolor: Color.flatWhiteDark}}/>
              </Row>
            </Column>
          </div>
          <div style={{display: processing === 1 ? 'none': 'block'}}>
            <CustomizedTables rows={rows} showInvoice={true}></CustomizedTables>
          </div>
          </>
          :
          <>
          <div style={{display: processing === 1 ? 'block': 'none'}}>
            <Column>
              <Row center width='100%'>
                <Skeleton sx={{width:'100%', bgcolor: Color.flatWhiteDark}}/>
              </Row>
              <Row center width='100%'>
                <Skeleton sx={{width:'100%', bgcolor: Color.flatWhiteDark}}/>
              </Row>
            </Column>
          </div>
          <div style={{display: processing === 1 ? 'none': 'block'}}>
            <Row bottom={10}><Header4 sx={{color:Color.flatWhite}}>{props.title}</Header4></Row>
            <CustomizedTables rows={rows} showInvoice={props.showInvoice}></CustomizedTables>
          </div>
          </>
          }
        </Column>
      }
    </Column>
    </>
  );
}

const MyPagination = styled(Pagination)(({ theme }) => ({
  [`& .MuiPaginationItem-root`]: {
    color: Color.flatWhite,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'rgb(0,0,0)',
    color: Color.flatWhite,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: Color.flatWhite,
  },
}));

const StyledTableRow = styled(TableRow)((props) => ({
  backgroundColor: props.clr,
  '& td,th': {
    borderColor:'rgba(74,74,74,1)'
  },  
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

/*const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(56,56,56,1)',
  },
  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(36, 36, 36, 1)',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));*/




function CustomizedTables(props) {
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  let colors = [];
  props.rows.map((row)=>{
    let k = _.findIndex(colors, (o)=>o.nn === row.nn);
    if (k === -1) {
      if (colors.length % 2 === 0) {
        colors.push({nn:row.nn,color:'rgba(36, 36, 36, 1)'});  
      } else {
        colors.push({nn:row.nn,color:'rgba(56,56,56,1)'});
      }
    }
  });
  return (
    <>
    <div style={{display: lt.max <= 8 ? 'block': 'none'}}>
    {props.rows.map((row) => (
      <Column width='100%' top={15} bottom={15} key={uuid()}>
        <Row top={0} bottom={20} width='100%' height={1} color={Color.flatWhite}></Row>
        <Row main={Axis.main.between}>
          <Row><Header6 sx={{color: Color.flatWhite}}>#{row.nn}</Header6></Row>
          <Row><Header6 sx={{color: Color.flatWhite}}>{row.dt}</Header6></Row>
        </Row>
        <Row main={Axis.main.between} top={10}>
          <Row><Caption sx={{color: Color.flatWhiteDark}}>{row.stage}</Caption></Row>
          {row.invoice && props.showInvoice ? <Row><Link color='#dbae3b' href={row.invoice} target="_blank" sx={{paddingLeft:'40px', height:'44px'}}>Счет</Link></Row> : ""}
        </Row>
        <Row top={5}><Body1 sx={{color: Color.flatWhite}}>{row.good}</Body1></Row>
        <Row main={Axis.main.between} top={20}>
          <Row><Subtitle2 sx={{color: Color.flatWhiteDark}}>Цена</Subtitle2></Row>
          <Row><Subtitle2 sx={{color: Color.flatWhite}}>{row.price}</Subtitle2></Row>
        </Row>
        <Row main={Axis.main.between} top={7}>
          <Row><Subtitle2 sx={{color: Color.flatWhiteDark}}>Количество</Subtitle2></Row>
          <Row><Subtitle2 sx={{color: Color.flatWhite}}>{row.qty}</Subtitle2></Row>
        </Row>
        <Row main={Axis.main.between} top={7} bottom={10}>
          <Row><Subtitle2 sx={{color: Color.flatWhiteDark}}>Сумма</Subtitle2></Row>
          <Row><Subtitle2 sx={{color: Color.flatWhite}}>{row.total}</Subtitle2></Row>
        </Row>
        
      </Column>
    ))}
    </div>
    <div style={{display: lt.max <= 8 ? 'none': 'block'}}>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Номер заказа</StyledTableCell>
            <StyledTableCell align="right">Дата заказа</StyledTableCell>
            <StyledTableCell align="left">Стадия</StyledTableCell>
            <StyledTableCell align="left">Товар</StyledTableCell>
            <StyledTableCell align="right">Цена</StyledTableCell>
            <StyledTableCell align="right">Количество</StyledTableCell>
            <StyledTableCell align="right">Сумма</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <StyledTableRow key={uuid()} clr={colors[_.findIndex(colors, (o)=>o.nn === row.nn)].color}>
              <StyledTableCell component="th" scope="row">
                {row.nn}
              </StyledTableCell>
              <StyledTableCell align="right">{row.dt}</StyledTableCell>
              <StyledTableCell align="left">{row.stage}</StyledTableCell>
              <StyledTableCell align="left">
                {row.good}
                {row.invoice && props.showInvoice ? <Row top={10}><Link color='#dbae3b' href={row.invoice} target="_blank">Счет</Link></Row> : ""}
              </StyledTableCell>
              <StyledTableCell align="right">{row.price}</StyledTableCell>
              <StyledTableCell align="right">{row.qty}</StyledTableCell>
              <StyledTableCell align="right">{row.total}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
    </>
  );
}

export default ReportsPage;