import logo from './logo.svg';
import './App.css';
import ReportsPage from './ReportsPage';

//setting up a React server
//https://stackoverflow.com/questions/44038456/how-to-setup-apache-server-for-react-route

function App() {
  return (
    <ReportsPage></ReportsPage>
  );
}

export default App;
